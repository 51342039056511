import React, { useState, useEffect} from 'react'
import moment from 'moment'
import axios from 'axios'

// NOTE:
//    - all bookings are retrieved from the backend but only the indicated Limit are displayed
//    - TODO pagination, with retrieval from the backend for the current page would be more efficient
//    - sorting is provided, the sort is across all data. This will become
//    - the limit is imposed because retrieving payment data from the backend is intense
//    - when a limit change is made, it again retrieves all bookings and obtains payment field
//    - while this page now allows sorting, it only performs this on the data retrieved from the server

export default function AdminBookings() {

    const [bookings, setBookings] = useState([])
    // restrict number of bookings to display
    const [bookingListLimit, setBookingListLimit] = useState(30);
    const [sortOrder, setSortOrder] = useState('desc');
    const [sortColumn, setSortColumn] = useState('createdAt');
    const [cancelling, setCancelling] = useState(false);
    const [eventInputId, setEventInputId] = useState("")

    useEffect(() => {
        getBookings()
    }, [bookingListLimit, sortColumn, sortOrder])

    const handleSort = (column) => {
        if (sortColumn !== column) {
            setSortColumn(column)
            // default descending on column change
            setSortOrder('desc')
        } else {
            // toggle sorting order if no column change
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
        }
    };

    const renderSortIndicator = (column) => {
        if (sortColumn === column) {
            return sortOrder === 'asc' ? '↑' : '↓';
        }
        return null;
    };

    const sortBookings = (bookings) => { //, sortColumn, sortOrder) => {
        return [...bookings].sort((a, b) => {
          if (sortColumn === 'date' || sortColumn === 'createdAt') {
            return sortOrder === 'asc' ? new Date(a[sortColumn]) - new Date(b[sortColumn]) : new Date(b[sortColumn]) - new Date(a[sortColumn]);
          } else {
            const aValue = a[sortColumn] || '';
            const bValue = b[sortColumn] || '';
            return sortOrder === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          }
        });
    };

    const getBookings = async () => {
      try {
          const res = await axios.get(`${process.env.REACT_APP_API_URL}/bookings/get-bookings`);

          // Get the payment status for each of the bookings
          // If many are card payments then this will be endpoint intense
          // particularly on backend. Hence include the booking list limit
          // Sorting is performed to order most recent first
          const modifiedBookings = await Promise.all(
              sortBookings(res.data, sortColumn, sortOrder)
              .slice(0, bookingListLimit)
                .map(async (booking) => {
                    try {
                        if (!!booking.subscriptionScheduleId) {
                            const response = await axios.post(`${process.env.REACT_APP_API_URL}/payments/get-payment-status-for-subscription-schedule`, {
                                subscriptionScheduleId: booking.subscriptionScheduleId,
                            });

                            booking.paymentStatus = response.data.paymentStatus;
                        } else {
                            booking.paymentStatus = "Not card payment";
                        }

                        return booking;
                    } catch (error) {
                        console.log('Error getting payment status:', error);
                        return booking;
                    }
                })
            );

            setBookings(modifiedBookings);
        } catch (error) {
            console.log(error);
        }
    }

    const handleLimitChange = (event) => {
        setBookingListLimit(Number(event.target.value));
    };

    const handleCancel = (eventId) => {
        setCancelling(true)

        axios.post(`${process.env.REACT_APP_API_URL}/events/cancel-event`, {_id: eventId})
        .then(() => {
            setEventInputId("");

            setCancelling(false)
            getBookings()
        })
        .catch((error) => {
            console.log(error)
            setCancelling(false)
        })
    };

    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '10px',
                marginBottom: '10px' }}
            >
                <label htmlFor="bookingLimit" style={{ marginRight: '10px' }}>
                  Number of Bookings to Display:{' '}
                </label>
                <select id="bookingLimit" onChange={handleLimitChange} value={bookingListLimit}>
                    <option value={10}>10</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                </select>
            </div>
            <table id='request-list-table'>
                <thead>
                    <tr id='thead-tr'>
                        <th onClick={() => handleSort('className')}>
                            Class Name {renderSortIndicator('className')}
                        </th>
                        <th onClick={() => handleSort('date')}>
                            Class Date {renderSortIndicator('date')}
                        </th>
                        <th onClick={() => handleSort('createdAt')}>
                            Booking Date {renderSortIndicator('createdAt')}
                        </th>
                        <th onClick={() => handleSort('teacherName')}>
                            Teacher Name {renderSortIndicator('teacherName')}
                        </th>
                        <th onClick={() => handleSort('studentName')}>
                            Student Name {renderSortIndicator('studentName')}
                        </th>
                        <th>Cancelled</th>
                        <th onClick={() => handleSort('paymentType')}>
                            Payment Type {renderSortIndicator('paymentType')}
                        </th>
                        <th>Payment Status</th>
                    </tr>
                </thead>
                <tbody>
                    {bookings.length < 1 ? (
                        <tr>
                            <td colSpan={6} style={{textAlign:'center'}}>
                                No bookings available
                            </td>
                        </tr>
                    ) : (
                        bookings
                        .map((booking, i) => {
                            return (
                                <tr key={i}>
                                    <td title={booking.eventId}>{booking.className}</td>
                                    <td>{moment(booking.date).format('ddd, DD MMM YYYY')}</td>
                                    <td>{moment(booking.createdAt).format('ddd, DD MMM YYYY')}</td>
                                    <td>{booking.teacherName}</td>
                                    <td>{booking.studentName}</td>
                                    <td>{booking.cancelled ? 'Cancelled' : 'Booked'}</td>
                                    <td>{booking.paymentType}</td>
                                    <td>{booking.paymentStatus}</td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>

            <div style={{ textAlign: 'center' }}>
                <label htmlFor="eventInput" style={{ fontFamily: 'Proxima' }}>Cancel Event (last resort):</label>
                <input
                    type="text"
                    id="eventInput"
                    value={eventInputId}
                    onChange={(e) => setEventInputId(e.target.value)}
                    placeholder="Enter event id"
                    disabled={cancelling}
                    style={{ fontFamily: 'Proxima', marginLeft: '10px', width : '200px'}}
                />
                <br />
                <button
                    onClick={() => handleCancel(eventInputId)}
                    disabled={cancelling}
                    style={{
                        backgroundColor: 'black',
                        color: 'white',
                        fontFamily: 'Proxima',
                        cursor: cancelling ? 'not-allowed' : 'pointer',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '5px',
                        marginTop: '10px',
                        marginBottom: '30px',
                    }}
                >
                    {cancelling ? "Cancelling..." : "Cancel Class"}
                </button>
            </div>
        </div>
    )
}

