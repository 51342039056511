import React, { useEffect, useState, useRef } from 'react'
import './Dashboard.css'

import axios from 'axios'
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js/auto';  // Import the Chart class
import 'chart.js/auto';  // Import the Chart class
import moment from 'moment'

export default function Dashboard() {

    const [students, setStudents] = useState([])
    const [teachers, setTeachers] = useState([])
    const [studios, setStudios] = useState([])
    const [events, setEvents] = useState([])
    const [bookings, setBookings] = useState([])
    const [passes, setPasses] = useState([])
    const [versionSummary, setVersionSummary] = useState([])
    const [totalUsersWithVersion, setTotalUsersWithVersion] = useState(0)
    const [totalUsersCount, setTotalUsersCount] = useState(0)
    const [usersLastWeek, setUsersLastWeek] = useState(0)
    const [usersLastMonth, setUsersLastMonth] = useState(0)
    const [passesLastWeek, setPassesLastWeek] = useState(0)
    const [passesLastMonth, setPassesLastMonth] = useState(0)
    const [purchaseCostPassesLastMonth, setPurchaseCostPassesLastMonth] = useState(0)
    const [purchaseCostClassesLastMonth, setPurchaseCostClassesLastMonth] = useState(0)

    useEffect(() => {
        getAllTeachers()
        getAllStudents()
        getAllStudios()
        getEvents()
        getBookings()
        getAllPasses()
    }, [])

    useEffect(() => {
        if (students.length > 0 && teachers.length > 0) {
            const allUsers = [...students, ...teachers]
            const versionUsers = allUsers.filter(user => user.versionInfo && user.versionInfo.version)
            const usersWithVersionCount = versionUsers.length
            const totalUsersCount = allUsers.length

            const groupedByVersion = versionUsers.reduce((acc, user) => {
                const version = user.versionInfo?.version

                if (!acc[version]) {
                    acc[version] = []
                }

                acc[version].push(user)

                return acc
            }, {})

            const updatedVersionSummary = Object.entries(groupedByVersion).map(([version, users]) => {
                const numberOfUsers = users.length
                const latestAccessDate = users.reduce((latestDate, user) => {
                    const userAccessDate = new Date(user.versionInfo?.lastLoggedIn)

                    return userAccessDate > latestDate ? userAccessDate : latestDate
                }, new Date(0));

                return { version, numberOfUsers, latestAccessDate }
            });

            // Calculate counts for users who logged in within the last week and last month
            const currentDate = new Date()
            const lastWeekDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000)
            const lastMonthDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000)

            const usersLastWeekCount = versionUsers.filter(user => new Date(user.versionInfo.lastLoggedIn) > lastWeekDate).length
            const usersLastMonthCount = versionUsers.filter(user => new Date(user.versionInfo.lastLoggedIn) > lastMonthDate).length

            setVersionSummary(updatedVersionSummary)
            setTotalUsersWithVersion(usersWithVersionCount)
            setTotalUsersCount(totalUsersCount)
            setUsersLastWeek(usersLastWeekCount)
            setUsersLastMonth(usersLastMonthCount)
        }
    }, [students, teachers])

    const getAllTeachers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/teachers/get-all-teachers`)
        .then((res) => {
            setTeachers(res.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const getAllStudents = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/students/get-students`)
        .then((res) => {
            setStudents(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const getAllStudios = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/studios/get-all-studios`)
        .then((res) => {
            setStudios(res.data)
        })
        .catch((error) => {
            console.log("Error getting studios", error)
        })
    }

    const getEvents = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/events/get-events`)
        .then((res) => {
            setEvents(res.data)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const getBookings = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/bookings/get-bookings`)
        .then((res) => {
            const now = new Date()
            const lastMonth = moment(now).subtract(1, 'months').toDate()

            // Set bookings state
            setBookings(res.data)

            // Calculate total cost of classes (bookings) purchased in the last month via Card
            const totalCostLastMonth = res.data.reduce((total, booking) => {
                const createdAt = new Date(booking.createdAt)

                // Only consider bookings in the last month where paymentType is 'Card'
                if (createdAt >= lastMonth && createdAt <= now && booking.paymentType === 'Card') {
                    return total + booking.price
                }

                return total
            }, 0)

            // Update the state with the total cost
            setPurchaseCostClassesLastMonth(totalCostLastMonth)
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const getAllPasses = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/passes/get-all-current-purchased-passes`)
        .then((res) => {
            const now = new Date()
            const lastWeek = moment(now).subtract(7, 'days').toDate()
            const lastMonth = moment(now).subtract(1, 'months').toDate()

            setPasses(res.data)
            console.log("passes", res.data)

            // Calculate passes purchased in the last week
            const lastWeekCount = res.data.filter(pass => {
                const createdAt = new Date(pass.createdAt);
                return createdAt >= lastWeek && createdAt <= now;
            }).length

            // Calculate passes purchased in the last month
            const lastMonthCount = res.data.filter(pass => {
                const createdAt = new Date(pass.createdAt)
                return createdAt >= lastMonth && createdAt <= now
            }).length


            // Calculate total cost of passes purchased in the last month
            const totalCostLastMonth = res.data.reduce((total, pass) => {
                const createdAt = new Date(pass.createdAt)
                if (createdAt >= lastMonth && createdAt <= now) {
                    return total + pass.cost
                }

                return total;
            }, 0)

            setPassesLastWeek(lastWeekCount)
            setPassesLastMonth(lastMonthCount)
            setPurchaseCostPassesLastMonth(totalCostLastMonth)

        })
        .catch((error) => {
            console.log(error)
        })
    }

  return (
    <div id='dashboard'>
        <div className='dash-stat'>
            <h2>Studios</h2>
            <h1>{studios.length}</h1>
        </div>
        <div className='dash-stat'>
            <h2>Teachers</h2>
            <h1>{teachers.length}</h1>
        </div>
        <div className='dash-stat'>
            <h2>Students</h2>
            <h1>{students.length}</h1>
        </div>
        <div className='dash-stat'>
            <h2>Events</h2>
            <h1>{events.length}</h1>
        </div>
        <div className='dash-stat'>
            <h2>Bookings</h2>
            <h1>{bookings.length}</h1>
        </div>

        <div style={{width: '90vw', margin: '1rem'}}>

        <UserSignUpChart users={students} teachers={teachers}/>

            <div style={{ margin: '40px' }}>
                <h2 style={{ textAlign: 'center', marginBottom: '10px' }}>Version Summary</h2>
                <table style={{ margin: 'auto' }}>
                    <thead>
                        <tr>
                            <th>Version</th>
                            <th>Number of Users</th>
                            <th>Latest Access Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {versionSummary
                            .sort((a, b) => parseFloat(b.version) - parseFloat(a.version))
                            .map(({ version, numberOfUsers, latestAccessDate }) => (
                                <tr key={version}>
                                    <td>{version}</td>
                                    <td>{numberOfUsers}</td>
                                    <td>{latestAccessDate.toDateString()}</td>
                                </tr>
                        ))}
                    </tbody>
                </table>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <p>Total Users with Version Info: {totalUsersWithVersion}</p>
                    <p>Total Users: {totalUsersCount}</p>
                    <p>Users logged in last week: {usersLastWeek}</p>
                    <p>Users logged in last month: {usersLastMonth}</p>
                    <p>Passes bought in the last week: {passesLastWeek}</p>
                    <p>Passes bought in the last month: {passesLastMonth}</p>
                    <p>Total pass purchase value last month: {purchaseCostPassesLastMonth}</p>
                    <p>Total card purchase value last month: {purchaseCostClassesLastMonth} </p>
                </div>
            </div>
        </div>
    </div>
  )
}


const UserSignUpChart = ({ users, teachers }) => {
    const chartRef = useRef(null);

    useEffect(() => {
      // Destroy the previous chart instance if it exists
      if (chartRef.current) {
        chartRef.current.destroy();
      }

      // Get the canvas element
      const canvas = document.getElementById('userSignUpChart');

      // Manipulate the data to get counts for each day
      const userCountsPerDay = users.reduce((counts, user) => {
        const date = new Date(user.createdAt).toLocaleDateString();
        counts[date] = (counts[date] || 0) + 1;
        return counts;
      }, {});


      // Convert the data for the chart
      const chartData = {
        labels: Object.keys(userCountsPerDay),
        datasets: [
          {
            label: 'Users Signed Up',
            data: Object.values(userCountsPerDay),
            fill: false,
            borderColor: 'rgba(75,192,192,1)', // Customize the line color
          },
        ],
      };


      // Create a new chart instance
      chartRef.current = new Chart(canvas, {
        type: 'line',
        data: chartData,
      });

      // Cleanup function to destroy the chart when the component is unmounted
      return () => {
        if (chartRef.current) {
          chartRef.current.destroy();
        }
      };
    }, [users]);

    return <canvas id="userSignUpChart"  />;
  };
