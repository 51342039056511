import React from 'react'
import './Navbar.css'
import logo from '../Assets/om.png'
import { Link } from 'react-router-dom'

export default function Navbar() {
  return (
    <header>
        <div style={{display:'flex', alignItems:'center'}}>
          <img id='nav-logo' src={logo} alt="Om Yoga Logo"/>
          <p style={{ fontFamily: 'Arual', fontSize: 30, margin: '0 0 0 20px'}}>Om Yoga Admin</p>
        </div>


        <nav id='desktop-nav'>
          <Link to={'/'} className='nav-link'>Dashboard</Link>
          <Link to={'asanas'} className='nav-link'>Asanas</Link>
          <Link to={'message'} className='nav-link'>Message</Link>
          <Link to={'support'} className='nav-link'>Support</Link>
          <Link to={'admin-studios'} className='nav-link'>Studios</Link>
          <Link to={'admin-teachers'} className='nav-link'>Teachers</Link>
          <Link to={'admin-students'} className='nav-link'>Students</Link>
          <Link to={'admin-bookings'} className='nav-link'>Bookings</Link>
        </nav>


        <div className="hamburger-menu">
          <input id="menu__toggle" type="checkbox" />
          <label className="menu__btn" htmlFor="menu__toggle">
          <span></span>
        </label>

        <ul className="menu__box">
          <Link to={'/'} className='nav-link'>Dashboard</Link>
          <Link to={'asanas'} className='nav-link'>Asanas</Link>
          <Link to={'message'} className='nav-link'>Message</Link>
          <Link to={'support'} className='nav-link'>Support</Link>
          <Link to={'admin-studios'} className='nav-link'>Studios</Link>
          <Link to={'admin-teachers'} className='nav-link'>Teachers</Link>
          <Link to={'admin-students'} className='nav-link'>Students</Link>
          <Link to={'admin-bookings'} className='nav-link'>Bookings</Link>
        </ul>
      </div>

    </header>
  )
}
