import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AdminUI from './Components/AdminUI.js';
import Login from './Components/Login';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RequireAuth from './Components/RequireAuth';
import { AuthProvider } from './Context/AuthContext';
import AdminStudios from './Components/AdminStudios';
import AdminTeachers from './Components/AdminTeachers';
import AdminStudents from './Components/AdminStudents';
import AdminBookings from './Components/AdminBookings';
import Dashboard from './Components/Dashboard';
import Asanas from './Components/Asanas';
import CreateAsana from './Components/CreateAsana';
import Message from './Components/Message.js';
import Messages from './Components/Messages';
import Chat from './Components/Chat'

function App() {
  return (
    <AuthProvider>
    <BrowserRouter>
      <Routes>
          {/*Public routes*/}
          <Route path='/login' element={<Login />} />
          {/*Admin routes*/}
          <Route element={<RequireAuth  />}>
            <Route path='/' element={<AdminUI />}>
              <Route index element={<Dashboard />} />
              <Route path='asanas' element={<Asanas />} />
              <Route path='message' element={<Message />} />
              <Route path='create-asana' element={<CreateAsana />} />
              <Route path='admin-studios' element={<AdminStudios />} />
              <Route path='admin-teachers' element={<AdminTeachers />} />
              <Route path='admin-students' element={<AdminStudents />} />
              <Route path='admin-bookings' element={<AdminBookings />} />
              <Route path='support' element={<Messages />} >
                <Route path=':id' element={<Chat />} />
              </Route>
            </Route>
          </Route>
      </Routes>
      <ToastContainer position='top-center'/>

    </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
