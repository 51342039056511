import React, { useState} from 'react'
import axios from 'axios'
import './Asanas.css'

export default function CreateAsana() {

    const [name, setName] = useState("")
    const [sanskrit, setSanskrit] = useState("")
    const [variations, setVariations] = useState("")
    const [category, setCategory] = useState("")
    const [pregnancyFriendly, setPregnancyFriendly] = useState(false)
    const [access, setAccess] = useState("")
    const [video, setVideo] = useState("")
    const [thumbnail, setThumbnail] = useState("")


    const handleSave = (e) => {
        e.preventDefault()

        let payload = {
            name, 
            sanskrit,
            category,
            variations,
            pregnancyFriendly,
            access,
            video,
            thumbnail,
            published: true
        }

        if(!name || !thumbnail || !video){
            alert("Please add name, video and thumbnail")
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/asanas/create-new-asana`, payload)
            .then((res) => {
                alert(res.data)
                setName("")
                setSanskrit('')
                setVariations("")
                setCategory("")
                setPregnancyFriendly(false);
                setAccess("")
                setVideo("")
                setThumbnail("")

            })
            .catch((e) => {
                console.log(e)
                alert("Error")
            })
        }


    }
  return (
    <div>
        <form id='asana-form'>
            <input placeholder='Name' value={name} onChange={(e) => setName(e.target.value)}/>
            <input placeholder='Sanskrit Name' value={sanskrit} onChange={(e) => setSanskrit(e.target.value)}/>
            <input placeholder='Video Link' value={video} onChange={(e) => setVideo(e.target.value)}/>
            <input placeholder='Thumbnail link' value={thumbnail} onChange={(e) => setThumbnail(e.target.value)}/>


            <label>Pregnancy Friendly?</label>
            <input type='checkbox' checked={pregnancyFriendly} onChange={() => setPregnancyFriendly(!pregnancyFriendly)}/>

            <label>Category</label>
            <select value={category} onChange={(e) => setCategory(e.target.value)}>
                <option value='' disabled>Select</option>
                <option value='Core'>Core</option>
                <option value='Upper Back'>Upper Back</option>
                <option value='Lower Back'>Lower Back</option>
                <option value='Upper Legs'>Upper Legs</option>
                <option value='Lower Legs'>Lower Legs</option>
                <option value='Shoulders'>Shoulders</option>
            </select>
           

            <label>Access</label>
            <select value={access} onChange={(e) => setAccess(e.target.value)}>
                <option value='' disabled>Select</option>
                <option value='Free'>Free</option>
                <option value='Premium'>Premium</option>
            </select>
           
           <button onClick={handleSave}>
               Save
           </button>

        </form>
    </div>
  )
}
