import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { useOutletContext, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useAuth } from '../Context/AuthContext'
import ModalManageTicket from './ModalManageTicket'

export default function Chat() {

    let { id } = useParams()
    let { currentUser } = useAuth()
    let userInfo //{ userInfo } = useUser()
    const [msgs, setMsgs] = useState([])
    const [message, setMessage] = useState("")
    const [showModal, setShowModal] = useState(false)

    const { getChats, senderName, selectedChat } = useOutletContext()

    useEffect(() => {
        getMsgs()
    }, [])

    useEffect(() => {
        axios.post(`${process.env.REACT_APP_API_URL}/chats/read-adminsupport-chat`, {chatId: selectedChat?._id})
        .then((res) => {
            getMsgs()
            getChats()
        })
        .catch((error) => {
            console.log(error)
        })
    }, [selectedChat])

    const getMsgs = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/chats/get-messages-by-chat-id`, {chatId: selectedChat?._id})
        .then((res) => {
            setMsgs(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const sendMessage = () => {
        if (!selectedChat) {
            toast.error("Select a chat to respond to")
            return
        }

        let payload ={
            chatId: selectedChat?._id,
            message,
            senderName: selectedChat.participants[0].name,
        }

        if (!message) {
            return
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/chats/send-adminsupport-message`, payload)
            .then((res) => {
                getMsgs(res.data)
                getChats()
                setMessage("")
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }

    const sendSupportResponse = () => {
        if (!selectedChat) {
            toast.error("Select a chat to respond to")
            return
        }

        let payload ={
            chatId: selectedChat?._id,
            message,
            senderName: selectedChat.participants[0].name,
            senderEmail: selectedChat.participants[0].email,
            ticketId: selectedChat.ticketId
        }

        if (!message) {
            return
        } else {
            axios.post(`${process.env.REACT_APP_API_URL}/chats/email-adminsupport-message`, payload)
            .then((res) => {
                getMsgs(res.data)
                getChats()
                setMessage("")
            })
            .catch((error) => {
                console.log(error)
            })
        }
    }

    const lastMessage = msgs.length > 0 ? msgs[msgs.length - 1] : null;
    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {
        scrollToBottom()
    }, [msgs]);

    const handleManageTicket = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div >
            {showModal &&
                <ModalManageTicket
                    selectedChat={selectedChat}
                    onClose={closeModal}
                    lastMessage={lastMessage}
                />
            }

            <div style={{overflow:'scroll', height: 'calc(85vh - 100px)'}}>
                {msgs.map((msg, i) => {
                    let split = msg.message?.split('\n')

                    return (
                        <div
                            style={{
                                width: '100%',
                                minHeight: 100,
                                margin: '1rem 0',
                                display:'flex',
                                alignItems:'center',
                                justifyContent: msg.sentBy !== "AdminSupport" ? "left" : "right"
                            }}
                            key={i}
                        >
                            {msg.sentBy === "AdminSupport" && (
                                userInfo?.logo ?
                                <div
                                    className='msg-avatar'
                                    style={{
                                        backgroundImage: `url(${userInfo.logo})`,
                                        backgroundSize:'cover',
                                        backgroundPosition:'center'
                                    }}
                                >
                                </div>
                                :
                                <div className='msg-avatar-sml'>
                                    {
                                        senderName.includes(" ") ?
                                        `${senderName.split(" ")[0].charAt(0)}${senderName.split(" ")[1].charAt(0)}` :
                                        senderName.charAt(0)
                                    }
                                </div>
                            )}

                            <div className={msg.sentBy !== "AdminSupport" ? 'other-sender-msg' : 'my-msg'}>
                                {split.map((line, i) => {
                                    return (
                                        <p
                                            key={i}
                                            style={{
                                                width: '100%',
                                                minHeight: 15,
                                                border: 'unset',
                                                resize: 'none',
                                                fontSize: 16,
                                                height: 'auto',
                                                wordWrap: 'break-word'
                                            }}
                                        >
                                              {line}
                                        </p>
                                    )
                                })}
                                <p style={{color: 'grey', fontSize: '0.8rem', marginTop: 10}}>
                                    {moment(msg.timeStamp).format('Do MMM YY, h:mm a')}
                                </p>
                            </div>
                        </div>
                    )
                })}

                <div ref={messagesEndRef}/>
            </div>

            <div>
                <div id='msg-box'>
                    {userInfo?.logo ?
                        <img className='msg-avatar-sml' src={userInfo?.logo}/>
                    :
                        <div className='msg-avatar-sml'></div>
                    }
                    <textarea
                        placeholder='Type your message'
                        className='om-input'
                        style={{width: '80%', minHeight: 80, marginRight: 10, resize:'none', paddingTop: 10}}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />

                    {selectedChat?.ticketId ?
                        <div  className="chat-button-container">
                            <button
                                className='sml-btn'
                                style={{width: 120}}
                                onClick={sendSupportResponse}
                                title="Respond to support message via email"
                            >
                                Email response
                            </button>

                            <button
                                className='sml-btn'
                                style={{width: 120, marginTop: 10}}
                                onClick={handleManageTicket}
                                title="Add internal note for the ticket"
                            >
                                Manage Ticket
                            </button>
                        </div>
                    :
                        <button
                            className='sml-btn'
                            onClick={sendMessage}
                            title="Respond to a message. Only possible to account holders."
                        >
                            Send
                        </button>
                    }
                </div>
            </div>
        </div>
    )
}
