import React, { useState } from 'react'
import './Message.css'
import axios from 'axios'

export default function Message() {

    const [header, setHeader] = useState("")
    const [subject, setSubject] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    const sendMsg = (sendTo) => {
        if(!header || !subject || !message){
            alert("Please add all fields")
        } else {
            setLoading(true)
            axios.post(`${process.env.REACT_APP_API_URL}/admin/send-message`, {sendTo, header, subject, message})
            .then(() => {
                //Clear state
                setHeader("");setSubject("");setMessage("")
                setLoading(false)
    
            })
            .catch((e) => {
                console.log(e)
                setLoading(false)
    
            })
        }
      
    }

  return (
        <div id='message'>
            <input placeholder='Subject' className='msg-input' type='text' value={subject} onChange={(e) => setSubject(e.target.value) }/>

            <input placeholder='Header'  className='msg-input' type='text' value={header} onChange={(e) => setHeader(e.target.value) }/>

            <textarea placeholder='Message'  id='msg-body' value={message}  onChange={(e) => setMessage(e.target.value)} />

            <button className='send-btn' style={loading ? {backgroundColor:'grey'} : null} onClick={() => sendMsg("All")} disabled={loading}>
                Send to All
            </button>
            <button className='send-btn' style={loading ? {backgroundColor:'grey'} : null} onClick={() => sendMsg("Teachers")} disabled={loading}>
                Send to Teachers
            </button>
            <button className='send-btn' style={loading ? {backgroundColor:'grey'} : null} onClick={() => sendMsg("Students")} disabled={loading}>
                Send to Students
            </button>

        </div>
   
  )
}
