import React,  {useEffect, useState} from 'react'
import axios from 'axios'

export default function AdminTeachers() {

    const [teachers, setTeachers] = useState([])

    useEffect(() => {
        getAllTeachers()
    }, [])

    const getAllTeachers = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/teachers/get-all-teachers`)
        .then((res) => {
            setTeachers(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const toggleApproved = (_id, checked) => {
        axios.post(`${process.env.REACT_APP_API_URL}/teachers/change-approved-status`, {_id, checked})
        .then(() => {
            getAllTeachers()
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const toggleAdmin = (_id, checked) => {
        axios.post(`${process.env.REACT_APP_API_URL}/teachers/admin-toggle`, {_id, checked})
        .then(() => {
            getAllTeachers()
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const togglePaymentSignup = (_id, checked) => {
        // toggle checkbox is guarded such that if signup has begun/completed it cannot be changed
        axios.post(`${process.env.REACT_APP_API_URL}/teachers/change-payment-signup-permission`, {_id, checked})
        .then(() => {
            getAllTeachers()
        })
        .catch((error) => {
            console.log(error)
        })
    }

    const styles = {
        tooltip: {
            position: 'fixed',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            zIndex: '9999',
        }
    }

    const handleMouseEnter = (event, deviceInfo) => {
        if (deviceInfo) {
            const { top, left } = event.target.getBoundingClientRect()

            const tooltip = document.createElement('div')
            tooltip.className = 'tooltip'

            tooltip.style.position = 'absolute'
            tooltip.style.top = `${top+40}px`
            tooltip.style.left = `${left+40}px`

            Object.assign(tooltip.style, styles.tooltip);

            const tooltipContent = `
                <div><strong>Device Type:</strong> ${deviceInfo.deviceType}</div>
                <div><strong>Brand:</strong> ${deviceInfo.brand}</div>
                <div><strong>Manufacturer:</strong> ${deviceInfo.manufacturer}</div>
                <div><strong>Model:</strong> ${deviceInfo.model}</div>
                <div><strong>OS:</strong> ${deviceInfo.osName} ${deviceInfo.osVersion}</div>
            `

            tooltip.innerHTML = tooltipContent
            document.body.appendChild(tooltip)
        }
    }

    const handleMouseLeave = () => {
        const tooltip = document.querySelector('.tooltip')
        if (tooltip) {
            tooltip.remove()
        }
    }

    return (
        <div>
            <table>
                <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Approved</th>
                    <th>Admin</th>
                    <th title="Allow teacher to integrate with Stripe to accept payments">Payments</th>
                </tr>

                {teachers.length < 1 ?
                    <tr>
                        <td colSpan={5}>No teachers added</td>
                    </tr>
                :
                    teachers.map((teacher, i) => {
                        return (
                            <tr key={i}>
                                <td
                                    style={{ cursor: 'pointer' }}
                                    onMouseEnter={(event) => handleMouseEnter(event, teacher.deviceInfo)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <img style={{ width: 50, height: 50, borderRadius: 25, objectFit: 'cover', objectPosition: 'center' }} src={teacher.img} alt={teacher.firstName} />
                                </td>
                                <td>{teacher.firstName} {teacher.lastName}</td>
                                <td>{teacher.email}</td>
                                <td>
                                    <input
                                        type='checkbox'
                                        checked={teacher.approved}
                                        onChange={() => toggleApproved(teacher._id, !teacher.approved)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type='checkbox'
                                        checked={teacher.admin}
                                        onChange={() => toggleAdmin(teacher._id, !teacher.admin)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type='checkbox'
                                        checked={teacher.connectData?.signupPermitted}
                                        onChange={() => togglePaymentSignup(teacher._id, !teacher.connectData?.signupPermitted)}
                                        disabled={teacher.connectData?.accountId && teacher.connectData.accountId.trim() !== ''}
                                    />
                                </td>
                            </tr>
                        )
                    })
                }
            </table>
        </div>
    )
}
