import React,  {useEffect, useState} from 'react'
import axios from 'axios'

export default function AdminStudios() {

    useEffect(() => {
        getAllStudios()
    }, [])

    const [studios, setStudios] = useState([])

    const getAllStudios = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/studios/get-all-studios`)
        .then((res) => {
            setStudios(res.data)
            console.log("studios", studios)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const toggleApproved = (_id, checked) => {
        axios.post(`${process.env.REACT_APP_API_URL}/studios/change-approved-status`, {_id, checked})
        .then(() => {
            getAllStudios()
        })
        .catch((error) => {
            console.log("Error toglling approved status", error)
        })
    }


  return (
    <div>
        <table>
            <tr>
                <th></th>
                <th>Name</th>
                <th>Email</th>
                <th>Approved</th>
                <th>Teachers</th>
            </tr>

            {studios.length < 1 ?
            <tr>
                <td colSpan={5}>No studios added</td>
            </tr>

            :

            studios.map((studio, i) => {
                return (
                    <tr key={i}>
                        <td><img style={{width: 50, height: 50, borderRadius: 25, objectFit: 'cover', objectPosition:'center'}} src={studio.logo} alt={studio.firstName}/></td>
                        <td>{studio.firstName} {studio.lastName}</td>
                        <td>{studio.email}</td>
                        <td><input type='checkbox' checked={studio.approved} onChange={() => toggleApproved(studio._id, !studio.approved)}/></td>
                        <td>{studio.teachers.length}</td>
                    </tr>
                )
            })
        }
        </table>
    </div>
  )
}
