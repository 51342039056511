import React, { useState, useEffect } from 'react'
import './Messages.css'
import search from '../Assets/search.png'
import moment from 'moment'
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom'
import { useAuth } from '../Context/AuthContext'
import axios from 'axios'

export default function Messages() {

    let navigate = useNavigate()
    let { currentUser } = useAuth()

    const [teacherChats, setTeacherChats] = useState([])
    const [chats, setChats] = useState([])
    const [selectedChat, setSelectedChat] = useState(null)
    const [tab, setTab] = useState(1)

    useEffect(() => {
        getChats()
    }, [])

    useEffect(() => {
        setSelectedChat(tab === 1 ? teacherChats[0] : chats[0])
    }, [tab])

    const getChats = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/chats/get-chats-by-id`, {id: 'AdminSupport'})
        .then((res) => {
            // set teacher chats - group with studio also
            // participant[0] represents initiator/sender
            const teacherSentChats = res.data.filter(chat =>
                chat.participants[0].type === "Teacher" || chat.participants[0].type === "Studio"
            )

            // Sort filtered chats by lastMsgAdded (descending)
            teacherSentChats.sort((a, b) => new Date(b.lastMsgAdded) - new Date(a.lastMsgAdded))
            setTeacherChats(teacherSentChats)

            // set student chats - group students and accountless users (non-logged in user)
            // participant[0] represents initiator/sender
            const studentSentChats = res.data.filter(chat =>
                chat.participants[0].type === "Student" || chat.participants[0].type === "Unregistered"
            )

            // Sort filtered chats by lastMsgAdded (descending)
            studentSentChats.sort((a, b) => new Date(b.lastMsgAdded) - new Date(a.lastMsgAdded));
            setChats(studentSentChats)

            if (!selectedChat) {
                if (tab === 1 && teacherSentChats.length > 0) {
                    setSelectedChat(teacherSentChats[0])
                } else if (tab === 2 && studentSentChats.length > 0) {
                    setSelectedChat(studentSentChats[0])
                } else {
                    setSelectedChat(null)
                }
            }
        })
        .catch((error) => {
            console.log("Error getting chats", error)
        })
    }


    return (
        <div>
            <div id='message-contents'>
                <div className='om-row' style={{justifyContent: 'space-between'}}>
                    <p className='om-header'>Inbox</p>
                    <div className='om-row'>
                        <div
                            className='om-input'
                            style={{paddingLeft: 'unset', width: 250, marginRight: 10, position: 'relative'}}
                        >
                            <img src={search} alt='Search icon' style={{height: 25, position: 'absolute', left: 10, top: 10}}/>
                            <input style={{border: 'unset', background: 'unset', height: '100%', width: '100%', paddingLeft: 50, fontSize: 16}}  placeholder='Search messages' />
                        </div>
                        <button className='primary-btn'>Search</button>
                    </div>
                </div>

                <div className='om-row'>
                    <div id='messages-left-container'>
                        <div className='tab-container' style={{justifyContent: 'left', margin: 0, borderRadius: 0}}>
                            <div className={tab === 1 ? 'tab-active' : 'tab'} onClick={() => setTab(1)}>
                                <p style={{color:'#313131'}}>Teachers</p>
                                <div className='tab-square'>
                                    <p style={{color:'#313131'}}>{teacherChats.length}</p>
                                </div>
                            </div>
                            <div className={tab === 2 ? 'tab-active' : 'tab'} onClick={() => setTab(2)}>
                                <p style={{color:'#313131'}}>Students</p>
                                <div className='tab-square'>
                                    <p style={{color:'#313131'}}>{chats.length}</p>
                                </div>
                            </div>
                        </div>

                        {tab === 1 && (
                            teacherChats.length < 1 ?
                                <p style={{textAlign:'center', color: 'grey', marginTop: 100}}>Your inbox is empty</p>
                            :
                                teacherChats.map((msg, i) => {
                                    const isSelected = selectedChat && selectedChat._id === msg._id;

                                    return (
                                        <div
                                            key={i}
                                            className={`msg-snapshot ${isSelected ? 'selected-chat' : ''}`}
                                            onClick={() =>{ setSelectedChat(msg); navigate(msg._id) } }
                                        >
                                            {msg.participants[0]?.img ?
                                                <div
                                                    className='msg-avatar'
                                                    style={{backgroundImage: `url(${msg.participants[0]?.img})`, backgroundSize:'cover', backgroundPosition:'center'}}
                                                >
                                                </div>
                                            :
                                                <div className='msg-avatar'>
                                                    <p>{msg.participants[0]?.name?.split(" ")[0]?.charAt(0)}{msg.participants[0]?.name?.split(" ")[1]?.charAt(0)}</p>
                                                </div>
                                            }

                                            <div
                                                style={{flexDirection: 'column', width: '80%', height: '100%', overflow: 'hidden'}}
                                            >
                                                <div
                                                    style={msg.receiverRead ? {display: 'flex', justifyContent: 'space-between', marginBottom: 5, color: 'grey', fontSize: 14} : {display: 'flex', justifyContent: 'space-between', marginBottom: 5, color: '#313131', fontSize: 14} }
                                                >
                                                    <p style={{textTransform: 'uppercase'}}>{msg.participants[0]?.name}</p>
                                                    {msg.ticketId &&
                                                     <span style={{color: 'grey', fontSize: '0.7rem', marginTop: '1.3em'}}>
                                                         ticket {msg.ticketId.slice(-10)}
                                                    </span>}
                                                    <p>{moment(msg.lastMsgAdded).fromNow()}</p>
                                                </div>

                                                <p style={ msg.receiverRead ? {marginTop: 0, color:'grey'} :{marginTop: 0, color:'#313131'}}>{msg.lastMsg}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            )
                        }

                        {tab === 2 &&
                            (chats.length < 1 ?
                                <p style={{textAlign:'center', color: 'grey', marginTop: 100}}>Your inbox is empty</p>
                            :
                                chats.map((msg, i) => {
                                    const isSelected = selectedChat && selectedChat._id === msg._id;

                                    return (
                                        <div
                                            key={i}
                                            className={`msg-snapshot ${isSelected ? 'selected-chat' : ''}`}
                                            onClick={() =>{ setSelectedChat(msg); navigate(msg._id) } }
                                        >
                                            <div className='msg-avatar'>
                                                <p>{msg.participants[0]?.name?.split(" ")[0]?.charAt(0)}{msg.participants[0]?.name?.split(" ")[1]?.charAt(0)}</p>
                                            </div>

                                            <div
                                                style={{flexDirection: 'column', width: '80%', height: '100%', overflow: 'hidden'}}
                                            >
                                                <div
                                                    style={msg.receiverRead ? {display: 'flex', justifyContent: 'space-between', marginBottom: 5, color: 'grey', fontSize: 14} : {display: 'flex', justifyContent: 'space-between', marginBottom: 5, color: '#313131', fontSize: 14} }
                                                >
                                                    <p style={{textTransform: 'uppercase'}}>{msg.participants[0]?.name}</p>
                                                    {msg.ticketId &&
                                                     <span style={{color: 'grey', fontSize: '0.7rem', marginTop: '1.3em'}}>
                                                         ticket {msg.ticketId.slice(-10)}
                                                    </span>}
                                                    <p>{moment(msg.lastMsgAdded).fromNow()}</p>
                                                </div>

                                                <p style={{marginTop: 0}}>{msg.lastMsg}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            )
                        }
                    </div>

                    <div id='messages-right-container'>
                        <Outlet context={{getChats, senderName: currentUser.displayName, selectedChat}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
