import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import './Asanas.css'
import axios from 'axios'

export default function Asanas() {

  const [asanas, setAsanas] = useState([])

  const getAsanas = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/asanas/get-all-asanas`)
    .then((res) => {
      setAsanas(res.data)
    })
    .catch((e) => {
      console.log(e)
    })
  }

  useEffect(() => {
    getAsanas()
  }, [])

  return (
    <div id='asanas'>
      <Link  style={{margin: '1rem auto'}}  to={'/create-asana'}>
        <button className='om-btn'>Add Asana</button>
      </Link>

      <table>
        <tr>
          <th>Name</th>
          <th>Sanskrit</th>
          <th>Variations</th>
          <th>Category</th>
          <th>Pregnancy Friendly</th>
          <th>Access</th>
        </tr>

        {asanas.length < 1 ?
          <tr>
            <td colSpan={6} style={{fontFamily:'Arual', textAlign:'center', padding: 20}}>No Asanas Added</td>
          </tr>

        :
        asanas.map((pose, i) => {
          console.log(pose)
          return (
            <tr key={i}>
              <td>{pose.name}</td>
              <td>{pose.sanskrit}</td>
              <td></td>
              <td>{pose.category}</td>
              <td>{pose.pregnancyFriendly}</td>
              <td>{pose.access}</td>
            </tr>
          )
        })
        }

      </table>
    </div>
  )
}
