import React,  {useEffect, useState} from 'react'
import axios from 'axios'

export default function AdminStudents() {

    const [students, setStudents] = useState([])

    useEffect(() => {
        getAllStudents()
    }, [])

    const getAllStudents = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/students/get-students`)
        .then((res) => {
            setStudents(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const toggleAdmin = (_id, checked) => {
        axios.post(`${process.env.REACT_APP_API_URL}/students/admin-toggle`, {_id, checked})
        .then(() => {
            getAllStudents()
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const convertAccount = (student) => {

      if(window.confirm("Are you sure you want to convert account?")){
        axios.post(`${process.env.REACT_APP_API_URL}/students/convert-to-teacher`, student)
        .then(() => {
            getAllStudents()
        })
        .catch((e) => {
            console.log(e)
        })
      } else {
        return
      }
    }

    const styles = {
        tooltip: {
            position: 'fixed',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            zIndex: '9999',
        }
    }

    const handleMouseEnter = (event, deviceInfo) => {

        if (deviceInfo) {
            const { top, left } = event.target.getBoundingClientRect()

            const tooltip = document.createElement('div')
            tooltip.className = 'tooltip'

            tooltip.style.position = 'absolute'
            tooltip.style.top = `${top+40}px`
            tooltip.style.left = `${left+20}px`

            Object.assign(tooltip.style, styles.tooltip)

            const tooltipContent = `
                <div><strong>Device Type:</strong> ${deviceInfo.deviceType}</div>
                <div><strong>Brand:</strong> ${deviceInfo.brand}</div>
                <div><strong>Manufacturer:</strong> ${deviceInfo.manufacturer}</div>
                <div><strong>Model:</strong> ${deviceInfo.model}</div>
                <div><strong>OS:</strong> ${deviceInfo.osName} ${deviceInfo.osVersion}</div>
            `

            tooltip.innerHTML = tooltipContent
            document.body.appendChild(tooltip)
        }
    }

    const handleMouseLeave = () => {
        const tooltip = document.querySelector('.tooltip')
        if (tooltip) {
            tooltip.remove()
        }
    }

    return (
        <div>
            <table>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th></th>
                    <th>Admin</th>
                </tr>

                {students.length < 1 ?
                    <tr>
                        <td colSpan={4}>No students added</td>
                    </tr>
                :
                    students.map((student, i) => {
                        return (
                            <tr key={i}>
                                <td
                                    style={{ cursor: 'pointer' }}
                                    onMouseEnter={(event) => handleMouseEnter(event, student.deviceInfo)}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    {student.firstName} {student.lastName}
                                </td>
                                <td>{student.email}</td>
                                <td>
                                    <button onClick={() => convertAccount(student)}>
                                        Convert to Teacher
                                    </button>
                                </td>
                                <td>
                                    <input
                                        type='checkbox'
                                        checked={student.admin}
                                        onChange={() => toggleAdmin(student._id, !student.admin)}
                                    />
                                </td>
                            </tr>
                        )
                    })
                }
            </table>
        </div>
    )
}
